import React from 'react';
import {AppProps} from 'next/app';

import '../styles/main.scss';

const App = ({Component, pageProps}: AppProps): React.JSX.Element => {
  return (
    <Component {...pageProps}/>
  );
};

export default App;
